import {controller, target} from '@github/catalyst'

@controller
class DiscussionSpotlightContainerElement extends HTMLElement {
  @target mainLink: HTMLAnchorElement

  openDiscussionLink() {
    const selection = window.getSelection()!
    const noTextSelected = !selection.toString()

    if (noTextSelected) {
      this.mainLink.click()
    }
  }
}
